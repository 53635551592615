@tailwind components;

@layer components {
  .table-search {
    @apply flex w-[22.5rem]  rounded-lg h-9 text-white-100;
  }
  .table-search-input {
    @apply w-full ml-3 text-sm bg-transparent text-white-100;
  }

  .table-search-input:focus {
    outline: none;
  }
}
