@tailwind components;

@layer components {
  .toaster {
    @apply fixed z-[1000];
  }

  .bottom-left {
    @apply bottom-[4.875rem] left-4;
  }

  .toast {
    @apply flex mb-2 rounded-[0.25rem] justify-between;
    max-width: 330px;
    min-width: 270px;
  }

  .next-action {
    font-size: 0.8rem;
    line-height: 30px;
    letter-spacing: 2px;
    font-variant-caps: all-small-caps;
  }

  .icon {
    @apply p-1 flex justify-center flex-col;
  }

  .body {
    @apply p-2;
    padding-bottom: 0.6rem;
    width: 80%;
  }

  .green {
    color: #2e7d32;
  }

  .blue {
    color: #0288d1;
  }

  .red {
    color: #d32f2f;
  }

  .orange {
    color: #ed6c02;
  }

  .b-green {
    border: 2px solid #2e7d32;
  }

  .b-blue {
    border: 1px solid #0288d1;
  }

  .b-red {
    border: 1px solid #d32f2f;
  }

  .b-orange {
    border: 1px solid #ed6c02;
  }

  .icon-bg-green {
    background-color: #2e7d32;
  }

  .icon-bg-blue {
    background-color: #0288d1;
  }

  .icon-bg-red {
    background-color: #d32f2f;
  }

  .icon-bg-orange {
    background-color: #ed6c02;
  }

  .bg-green {
    background-color: #eaf2ea;
  }

  .bg-blue {
    background-color: #d9edf8;
  }

  .bg-red {
    background-color: #fbeaea;
  }

  .bg-orange {
    background-color: #fdf0e6;
  }

  @media only screen and (max-width: 390px) {
    .toast {
      max-width: 300px;
      min-width: 250px;
    }
  }
}
