.loading-spinner-ellipse {
  animation: spin 1.7s linear infinite;
}

.loading-spinner-icon {
  animation: fadeInOut 1.7s linear infinite;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}