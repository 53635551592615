@tailwind components;

@layer components {
  .post-favorited-modal {
    @apply h-auto lg:!w-[30.375rem] sm:!pb-[1.8rem] md:!pb-[3.75rem] sm:!px-3;
  }
  .fixed-height {
    @apply !h-[25rem];
  }

  .favorite-collection-modal-card-image-wrapper {
    @apply w-16 h-full rounded;
  }
}
