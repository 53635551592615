@tailwind components;

/* overwriting react-day-picker variables */
.rdp {
  --rdp-accent-color: #3b3a42;
  --rdp-outline: 0px transparent !important;
  --rdp-outline-selected: 0px transparent !important;
  --rdp-background-color: transparent;
}

@layer components {
  .datepicker-wrapper {
    @apply relative flex items-center h-full px-4 cursor-pointer w-fit text-left focus:outline-none !bg-base-white;
  }

  .datepicker-wrapper svg {
    @apply inline mr-4;
  }

  .datepicker-container {
    @apply flex items-center h-6 p-1 duration-100 ease-in-out cursor-pointer;
  }

  .datepicker-container--disabled {
    @apply !cursor-not-allowed text-cement-400;
  }

  .datepicker-dropdown {
    @apply absolute p-0 shadow w-[21rem] max-h-fit top-[4.25rem] lg:top-[3.75rem];
  }

  .map-page .datepicker-dropdown {
    top: 3.6rem;
  }

  .datepicker-dropdown .rdp {
    @apply m-0 w-full min-h-[19rem];
  }

  .datepicker-dropdown .rdp-caption {
    @apply bg-slate-500 h-[3.1875rem] px-4 py-2 rounded-t-md;
  }

  .datepicker-dropdown .rdp-caption_label {
    @apply text-[0.875rem] font-medium leading-[1.5rem] tracking-[2%] !border-none;
  }

  .datepicker-dropdown .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    @apply bg-slate-500;
  }

  .datepicker-dropdown .rdp-table {
    @apply mb-2 w-full bg-slate-500/[0.65] rounded-b-md max-w-none;
  }

  .datepicker-dropdown .rdp-month {
    @apply w-full bg-slate-400;
  }

  .datepicker-dropdown .rdp-months {
    @apply mx-4 duration-100 ease-in-out;
  }

  .datepicker-dropdown .rdp-head_cell {
    @apply text-sm font-semibold h-[2.5rem] w-[2.5rem];
  }

  .datepicker-dropdown .rdp-cell {
    @apply h-[2.5rem] w-[2.5rem];
  }

  .datepicker-dropdown .rdp-cell button {
    @apply h-[2.5rem] max-w-[2.5rem] w-[2.5rem];
  }

  .datepicker-dropdown .rdp-day {
    @apply text-sm font-normal;
  }

  .datepicker-dropdown .rdp-day_outside {
    @apply opacity-100 text-cement-500;
  }

  .datepicker-dropdown .rdp-day_selected {
    @apply bg-freight-100 text-slate-500;
  }

  .datepicker-dropdown .rdp-caption_dropdowns {
    @apply justify-between w-full px-2;
  }

  .datepicker-dropdown .rdp-dropdown_month {
    @apply w-28;
  }

  .datepicker-dropdown .rdp-dropdown_year {
    @apply justify-end w-16;
  }

  .datepicker-dropdown .rdp-dropdown {
    @apply !bg-slate-400 p-3 w-full;
  }

  .datepicker-modal .primary-modal-sub {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .datepicker-modal .primary-modal-box {
    bottom: 0;
    border-radius: 0;
    padding-top: 0.9rem;
    height: 4.6rem;
    background-color: #252429;
    color: white;
    padding-left: 0;
    padding-right: 0;
  }

  .datepicker-modal .modal-close-btn {
    color: white;
    top: 1.3rem;
    right: 1.6rem;
  }

  .datepicker-modal .datepicker-modal-title {
    margin-bottom: 1.3rem;
    padding-top: 0.2rem;
  }

  .datepicker-modal .datepicker-list-wrapper {
    background-color: rgb(59 58 66 / var(--tw-bg-opacity));
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  .datepicker-modal .datepicker-list-wrapper .datepicker-dropdown {
    box-shadow: none;
  }
}
