.first-child-border-round {
    border-top-right-radius: 1rem !important;
}

.last-child-border-round {
    border-bottom-right-radius: 1rem !important;
}

.margin-bottom-all {
    margin-bottom: 0.06rem !important;
}