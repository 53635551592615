@tailwind components;

@layer components {
  .checkboxDropdown {
    @apply [&_#selected-option-undefined]:!text-ellipsis;
  }

  .checkboxDropdown #selected-option-undefined {
    @apply block w-full overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .checkboxDropdown .dropdown-list ul {
    @apply !columns-2 grid;
  }

  .checkboxDropdown .dropdown-list ul li:last-of-type {
    @apply col-span-2;
  }

  @media screen and (max-width: 449px) {
    .checkboxDropdown .dropdown-list ul {
      @apply !columns-1 grid;
      display: flex;
      flex-direction: column;
    }
  }
}