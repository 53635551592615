@tailwind components;

@layer components {
  .search-result-skeleton {
    @apply box-border flex mb-4 xl:mr-4 overflow-hidden border rounded-lg border-cement-100 h-40 xl:h-40;
  }

  .skeleton-container-right {
    @apply flex flex-1 flex-col justify-between pt-6 pb-2 pl-3 pr-2 xl:pt-12 xl:pb-6 xl:pl-6 xl:pr-4 animate-pulse w-72;
  }

  .image-skeleton,
  .name-skeleton,
  .address-skeleton,
  .attribute-skeleton {
    @apply bg-cement-200;
  }

  .image-skeleton {
    @apply animate-pulse h-40 xl:h-40 w-full xl:w-72;
  }

  .name-skeleton {
    @apply h-5 w-52 mb-1.5;
  }

  .address-skeleton {
    @apply h-3 w-44;
  }

  .skeleton-container-attributes {
    @apply flex flex-col flex-wrap h-8 xl:h-12 mt-2 xl:mt-7;
  }

  .attribute-skeleton {
    @apply h-3.5 w-20 mb-1.5;
  }

  @media only screen and (min-width: 612px) and (max-width: 933px) {
    .search-result-skeleton-gap:nth-child(odd) {
      margin-right: 1rem;
    }
  }
  @media only screen and (min-width: 934px) and (max-width: 949px) {
    .search-result-skeleton-gap:nth-child(odd) {
      margin-right: 1.5rem;
    }
  }
  @media only screen and (min-width: 950px) and (max-width: 1023px) {
    .search-result-skeleton-gap:nth-child(odd) {
      margin-right: 3.8rem;
    }
  }
}
