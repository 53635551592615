@tailwind components;

@layer components {
  /*  large */
  .authentication-menu {
    display: flex;
    align-items: center;
  }
  .authentication-menu-mobile {
    display: none;
  }
  .knowledge-center {
    content: '';
    position: absolute;
    border-top: 1px solid rgb(163 160 181) !important;
    width: -webkit-fill-available;
    margin-left: 3%;
    margin-right: 3%;
  }
  .szh-menu-container-header-menu {
    right: 0;
  }

  /*  small */
  @media only screen and (max-width: 767px) {
    .szh-menu-container-header-menu {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 99 !important;
      background: rgb(58 70 73 / var(--tw-bg-opacity));
    }
    .authentication-menu {
      display: none;
    }
    .authentication-menu-mobile {
      display: block;
      padding-top: 2px;
    }
  }
}
