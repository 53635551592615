@tailwind components;

@layer components {
  .map-modal-container div.primary-modal-sub {
    position: unset;
    margin-left: unset;
    margin-right: unset;
    max-width: unset;
  }
  .map-modal-close-btn-lower {
    display: none;
  }
  .map-modal-li st-item:hover {
    background-color: #3b3a42b2;
    cursor: pointer;
    border-radius: 4px;
  }

  .map-modal-list-item.active {
    border-color: #efefef;
    border-width: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3b3a42b2;
  }

  .map-modal-list-item:hover {
    opacity: 0.8;
  }

  .map-modal-content {
    max-width: 96vw;
    max-height: 86vh;
  }

  .map-modal-heading-mobile {
    display: none;
  }

  .collection-title {
    min-width: 55%;
  }

  .collection-container {
    max-width: 95%;
  }

  .map-modal-clear-section {
    float: right;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .collection-title {
      max-width: 60%;
      min-width: 60%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .collection-title {
      max-width: 50%;
      min-width: 50%;
    }
  }

  @media only screen and (max-width: 767px) {
    .map-modal-close-btn-lower {
      display: block;
      position: absolute;
      top: 0.4rem;
      right: 0.4rem;
      cursor: pointer;
    }
    .map-modal-content {
      max-width: 100%;
    }
    .map-modal-mapbox {
      width: 100vw;
    }
    .collection-title {
      max-width: 55%;
      min-width: 55%;
      @apply mr-2;
    }
    .collection-container {
      max-width: 95%;
    }
    .map-modal-container div.primary-modal-box {
      height: 100vh;
    }
    .map-modal-pins {
      width: 100vw;
      position: fixed;
      bottom: 0;
      height: 15.5rem;
      z-index: 1;
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
      overflow: auto;
    }
    .map-modal-container div.primary-modal-sub {
      position: absolute;
      top: 0;
    }
    .map-modal-heading-mobile {
      justify-content: space-between;
      display: flex;
      @apply px-4;
    }
    .map-modal-close {
      display: none;
    }
    .map-modal-close-btn {
      position: absolute;
      top: 1rem;
      right: 1.4rem;
      cursor: pointer;
    }
    .map-modal-close-btn svg {
      height: 2rem;
      width: 2rem;
    }
    .map-modal-close-icon {
      font-size: 2rem;
    }
    .map-modal-toolbar {
      top: 15%;
    }
    .map-modal-collection-name {
      display: none;
    }
    .map-modal-listing-wrapper {
      height: auto !important;
    }
    .sort-outer-container {
      min-width: inherit;
    }
    .map-modal-clear-section {
      float: left;
    }
  }
}
