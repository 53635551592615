@tailwind components;

@layer components {
  .text-area {
    @apply bg-cement-100 border border-cement-100 focus:bg-white-100 overflow-hidden focus:border-black h-14 max-w-[10rem] outline-none py-4 pl-4 pr-2 rounded-lg w-full;
    resize:none;
  }

  .text-area--error {
    @apply !bg-white-100 !border-freight-200 focus:!bg-white-100 focus:!border-freight-200;
  }
}
