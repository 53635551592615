@tailwind components;

@layer components {
  @media only screen and (max-width: 767px) {
    .back-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 6em;
      min-width: auto;
    }

    .back-header > .group {
      width: calc(100% - 2rem);
    }

    .back-header .cursor-pointer {
      height: 28px;
    }
  }
}
