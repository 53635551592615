@tailwind components;

@layer components {
  @media only screen and (min-width: 1024px) and (max-width: 1350px) {
    .map-section {
      width: 50% !important;
    }
    .search-results,
    .report-list-container {
      width: 50% !important;
    }
  }
  @media only screen and (min-width: 1351px) and (max-width: 1550px) {
    .map-section {
      width: 58% !important;
    }
    .search-results,
    .report-list-container {
      width: 42% !important;
    }
  }
  @media only screen and (min-width: 1551px) {
    .map-section {
      width: calc(100% - 41.875rem) !important;
    }
    .search-results,
    .report-list-container {
      width: 41.875rem !important;
    }
  }
  
  .infinite-scroll-comp::-webkit-scrollbar {
    display: none;
  }

  .search-results {
    @apply bg-white-100 w-full;
  }

  .search-results-empty {
    @apply flex flex-col items-center justify-center h-full;
  }

  .search-results-empty > svg {
    @apply text-cement-400;
  }

  .search-results-empty > h6 {
    @apply py-2;
  }

  .search-results-empty > p {
    @apply text-slate-300;
  }

  .search-results-container {
    @apply pt-2 h-[calc(100vh-14rem)];
  }

  .search-results-counter {
    @apply flex items-center justify-between py-2 px-4 lg:py-4;
  }
  .search-results-header-container {
    @apply flex items-center justify-between mx-4;
  }

  .result-cards-container {
    @apply h-[calc(100vh-11.75rem)] pb-6 overflow-hidden xl:overflow-auto;
  }

  .report-list-box {
    @apply bg-white-100 border-t border-cement-300 flex py-5 px-4 lg:p-6 w-full;
    box-shadow: 0rem -0.1875rem 0.625rem 0.1875rem rgba(21, 24, 24, 0.09);
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .report-list-box {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 1023px) {
    .search-results {
      display: none;
    }
    .search-results.show-view-list {
      display: block;
      position: absolute;
      z-index: 70;
    }
    .search-results-container {
      @apply pt-2 h-[calc(100vh-8rem)];
    } 
    
    .result-cards-container {
      @apply h-[calc(100vh-12.75rem)];
    }
    .infinite-scroll-comp {
      flex-direction: row;
    }
  }

  @media (min-width: 640px) and (max-width: 1023px) {
    .List.scroll-smooth > div {
      display: flex;
      flex-wrap: wrap;
      height: auto !important;
      position: relative;
    }

    .list-item-prop {
      width: 50% !important;
      position: relative;
    }

    .infinite-scroll-component.infinite-scroll-comp {
      height: calc(100vh - 17.5rem) !important;
    }

    .search-results-empty {
      height: 80vh;
    }
    
    .search-results-container {
      height: calc(100vh - 8.5rem);
    }
  }

  @media only screen and (max-width: 639px) {
    .search-results-container {
      height: calc(100vh - 8.5rem);
    }
    .search-results-empty {
      height: 80vh;
    }
    .list-item-prop {
      position: relative;
    }
  }
}
