@tailwind components;

@layer components {
  /*  large */
  footer {
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    min-height: 52px;
    justify-content: space-between;
  }

  footer .brand img {
    height: 1.75rem;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 76%;
  }

  .social-media {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .policy-copywright {
    display: flex;
    justify-content: space-between;
    width: 20%;
    align-items: center;
  }

  @media only screen and (max-width: 1400px) {
    .footer-row {
      width: 69%;
    }
    .policy-copywright {
      width: 26%;
    }
  }

  @media only screen and (max-width: 1300px) {
    .footer-row {
      width: 66%;
    }
    .policy-copywright {
      width: 28%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .footer-row {
      width: 62%;
    }
    .policy-copywright {
      width: 32%;
    }
  }

  /*  medium */
  @media only screen and (max-width: 1023px) {
    footer {
      display: flex;
      padding-left: 2rem;
      padding-right: 2rem;
      min-height: 5.5rem;
      justify-content: space-between;
    }
    footer .brand img {
      height: 2rem;
    }
    .footer-row {
      width: 60%;
    }
    .policy-copywright {
      width: 34%;
      padding-top: 2.2rem;
    }
  }

  /*  small */
  @media only screen and (max-width: 767px) {
    footer {
      display: block;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      min-height: 6rem;
    }

    footer .brand img {
      height: 1.4rem;
    }

    .policy-copywright {
      padding-top: 1.4rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .copywright {
      width: 60%;
      text-align: right;
    }

    .footer-row {
      width: 100%;
    }
  }
  @media only screen and (max-width: 490px) {
    .copywright {
      width: 50%;
      text-align: right;
    }
  }
  @media only screen and (max-width: 400px) {
    .copywright {
      width: 43%;
      text-align: right;
    }
  }
}
