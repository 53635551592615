.loader {
  background: transparent;
  border: 2px solid #ffffff;
  border-top: 2px solid #b2613a;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
