@tailwind components;

@layer components {
  .text-input {
    @apply bg-cement-100 border border-cement-100 focus:bg-white-100 focus:border-black h-12 min-w-[9.4rem] outline-none pl-4 rounded-lg w-full;
  }

  .text-input--error {
    @apply !bg-white-100 !border-freight-200 focus:!bg-white-100 focus:!border-freight-200;
  }
}
