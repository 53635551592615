@tailwind components;

@layer components {
  .collection-card-option-menu {
    @apply rounded-lg bg-white-400 shadow w-[11.375rem] pl-5 py-2;
  }
  .collection-card-option-menu-item {
    @apply flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100;
  }
  .collection-card-option-menu-item.disabled {
    @apply cursor-not-allowed opacity-60 hover:text-base-black;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .collection-cards-container {
    width: 17rem !important;
    margin-top: 0px !important;
    margin-bottom: 2rem !important;
  }
  .collection-cards-image {
    width: 14.875rem !important;
  }
}
