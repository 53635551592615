@tailwind components;

@layer components {
  .link {
    @apply text-sm underline;
  }

  .link--nounderline {
    text-decoration-line: none;
  }

  .link--default {
    @apply cursor-pointer hover:text-slate-200 text-slate-300;
  }

  .link--default--dark {
    @apply cursor-pointer hover:text-cement-300 text-white-100;
  }

  .link--disabled {
    @apply cursor-not-allowed text-cement-200;
  }

  .link--disabled--dark {
    @apply cursor-not-allowed text-slate-300;
  }

  .link--error {
    @apply cursor-not-allowed text-freight-200;
  }

  .anchor--disabled {
    @apply pointer-events-none;
  }
}
