@tailwind components;

@layer components {
  .map-search-input,
  .map-date-input,
  .map-filter-modal,
  .map-more-filters {
    padding-right: 1rem;
  }

  .map-filter-modal {
    min-width: 14rem;
  }

  .map-date-input {
    min-width: 13rem;
  }

  .map-more-filters {
    min-width: 6.2rem;
  }

  .map-clear-filters {
    max-width: 2.5rem;
  }

  .map-clear-filters:hover > span > a > svg > path {
    fill: rgb(183 183 183 / 1);
  }

  .map-date-input .datepicker-wrapper {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .map-search-input .search-input-wrapper {
    padding-left: 0.6rem;
  }

  .map-search-button .btn-default-outline--default-dark-theme {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .map-search-input .search-input-pill p {
    max-width: 19vw;
  }

  /* Large */
  @media only screen and (max-width: 1325px) {
    .map-filter-modal {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    .map-date-input,
    .map-clear-filters,
    .map-listing-group-button {
      display: none;
    }

    .map-search-input .search-input {
      min-width: 58vw;
    }

    .map-search-input .search-input-pill p {
      max-width: 50vw;
    }

    .map-more-filters {
      min-width: 0;
    }
  }

  /* Mobile */
  @media only screen and (max-width: 767px) {
    .map-search-input,
    .map-date-input,
    .map-filter-modal,
    .map-more-filters {
      padding-right: 0.5rem;
    }
    .map-more-filters {
      padding-right: 0;
    }
    .map-search-input .search-input {
      min-width: 52vw;
    }
    .map-search-input .search-input-pill p {
      max-width: 44vw;
    }
    .map-search-button {
      margin-left: 0.5rem;
    }
  }

  @media only screen and (max-width: 639px) {
    .map-search-input .search-input {
      min-width: 62vw;
    }
    .map-search-input .search-input-pill p {
      max-width: 55vw;
    }
  }

  @media only screen and (max-width: 499px) {
    .map-search-input .search-input {
      min-width: 54vw;
    }
    .map-search-input .search-input-pill p {
      max-width: 46vw;
    }
  }

  @media only screen and (max-width: 399px) {
    .map-search-input .search-input {
      min-width: 49vw;
    }
    .map-search-input .search-input-pill p {
      max-width: 40vw;
    }
  }

  @media only screen and (max-width: 319px) {
    .map-search-input .search-input {
      min-width: 56vw;
    }
    .map-search-input .search-input-pill p {
      max-width: 42vw;
    }
  }

  /* min values */
  @media only screen and (min-width: 1024px) {
    .map-search-input .search-input {
      min-width: 18rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    .map-search-input .search-input {
      min-width: 21rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    .map-search-input .search-input {
      min-width: 24rem;
    }
  }
}
