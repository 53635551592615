@tailwind components;

@layer components {
  /*  large */
  nav {
    height: 52px;
  }

  nav .brand {
    height: 1.75rem;
    margin-left: 0.8rem;
  }

  nav .brand-mobile {
    display: none;
  }

  nav .nav-right {
    margin-right: 2rem;
  }

  /*  medium */
  @media only screen and (max-width: 1023px) {
    nav {
      height: 52px;
    }

    nav .brand {
      height: 1.75rem;
      margin-left: 1rem;
    }

    nav .nav-right {
      margin-right: 1rem;
    }
  }

  /*  small */
  @media only screen and (max-width: 767px) {
    nav {
      height: 52px;
    }

    nav .brand {
      display: none;
    }

    nav .brand-mobile {
      height: 1.6rem;
      margin-left: 1.6rem;
      display: block;
    }

    nav .nav-right {
      margin-right: 1.6rem;
    }

    nav .nav-items {
      display: none;
    }
  }
}
