@tailwind components;

@layer components {
    .audit-info {
        width: -webkit-fill-available;
        text-align: end;
    }
    
    @media only screen and (max-width: 767px) {
        .audit-info {
            text-align: start;
        }
    }
}